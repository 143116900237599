/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Utils } from '@busuu/legacy-core';
/**
 * Private vars
 */
const mainWrapper = Utils.getById('main-wrapper');
const menuButton = Utils.getById('js-menu-toggle');
const navElement = Utils.getById('js-responsive-nav');
const navElementTransparent = Utils.getById('js-responsive-nav-transparent');
const menuButtonTransparent = Utils.getById('js-menu-toggle-transparent');
const activeClass = 'responsive-nav--active';
/**
 * If the nav is open and the user clicked outside of his area, hide it.
 */
const hideNav = (e) => {
    const targetIsChild = (navElement && Utils.hasChild(navElement, e.target)) ||
        (navElementTransparent && Utils.hasChild(navElementTransparent, e.target));
    const navIsActive = (navElement && Utils.hasClass(navElement, activeClass)) ||
        (navElementTransparent && Utils.hasClass(navElementTransparent, activeClass));
    if (!targetIsChild && navIsActive) {
        navElement && Utils.removeClass(navElement, activeClass);
        navElementTransparent && Utils.removeClass(navElementTransparent, activeClass);
    }
};
/**
 * Show/Hide the nav when the user clicks on the menu button
 */
const toggleNav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur(); // Remove focus from menu to be able to hide it
    }
    navElement && Utils.toggleClass(navElement, activeClass);
    navElementTransparent && Utils.toggleClass(navElementTransparent, activeClass);
};
const init = () => {
    if ((!menuButton && !menuButtonTransparent) || (!navElement && !navElementTransparent)) {
        return null;
    }
    /**
     * By default we remove the activeClass on Init because we want to hide
     * the menu for Safari users when they click the back button of the
     * browser
     */
    navElement && Utils.removeClass(navElement, activeClass);
    menuButtonTransparent && Utils.removeClass(navElementTransparent, activeClass);
    /**
     * Add click event to the body because we want to hide the nav when
     * user clicks outside of it
     */
    menuButton && menuButton.addEventListener('touchstart', toggleNav);
    menuButton && menuButton.addEventListener('click', toggleNav);
    menuButtonTransparent && menuButtonTransparent.addEventListener('touchstart', toggleNav);
    menuButtonTransparent && menuButtonTransparent.addEventListener('click', toggleNav);
    mainWrapper.addEventListener('touchstart', hideNav);
    mainWrapper.addEventListener('click', hideNav);
};
init();
