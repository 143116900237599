export const states = {
    WARNING: 'warn',
    SUCCESS: 'success',
    ERROR: 'error',
};

export const stateConfigs = {
    [states.SUCCESS]: {
        className: 'toaster__notification--success',
    },
    [states.WARNING]: {
        className: 'toaster__notification--warn',
    },
    [states.ERROR]: {
        className: 'toaster__notification--error',
    },
};
