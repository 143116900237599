import {storeCaptchaBypassToken} from '@busuu/legacy-core';

/**
 * Common Scripts used accross all the pages
 */
import 'exceptions/exception-controller.js';
import 'polyfills/samsung-browser-v7.2.js';
import 'components/responsive-nav';
import 'components/language-switcher-select';
import 'components/language-switcher-dropdown';
import 'forms/highlight-labels.js';
import 'tracking/tracking-controller.ts';
import 'tracking/common/tracking-common.js';

storeCaptchaBypassToken();
