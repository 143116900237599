import endpointConstant from 'endpoint/endpoint-constant.js';
import SharedDataService from 'data/shared-data-service.js';

// Endpoints that require captcha
const captchaBypassedEndpoints = ['login', 'forgottenPassword', 'resetPassword', 'register'];

/**
 * Returns a host for our API base
 * NB: Build time only. Can't be dynamic (runtime).
 * @param  {String} apiType
 * @return {String}
 * @private
 */
const generateHost = (apiType) => {
    switch (apiType) {
        case 'SSOAPI':
            return process.env.SSOAPI;
        case 'liveAPI':
            return process.env.liveAPI;
        case 'baseAPI':
        default:
            return process.env.baseAPI;
    }
};

/**
 * Returns an endpoint
 * @param {String} endpoint
 * @param {String} apiType - optional
 * @param {Object} replacements - list of params to replace
 *    eg: { ':userId': 'fsejgopskoges', '{discountCoupon}': 1234 }
 * @returns {String} url
 */
const generateEndpoint = (endpoint, apiType = null, replacements = {}) => {
    const host = generateHost(apiType);
    const relativeEndpointURL = endpointConstant.endpoints[endpoint];
    let absoluteEndpointURL = `${host}${relativeEndpointURL}`;

    // Handle any replacements of params
    Object.entries(replacements).forEach(([param, replacement]) => {
        absoluteEndpointURL = absoluteEndpointURL.replace(param, replacement);
    });

    return absoluteEndpointURL;
};

/**
 * Generates an endpoint with a captcha bypass token
 * as a query parameter if found in the session storage
 * @param {String} endpoint
 * @return {String}
 */
const getCaptchaBypassedEndpoint = (endpoint) => {
    const captchaBypassToken = SharedDataService.getQACaptchaToken();
    const generatedEndpoint = generateEndpoint(endpoint);

    if (endpoint === 'captcha') {
        return `${generatedEndpoint}&captcha_token=${captchaBypassToken}`;
    }

    if (captchaBypassToken && captchaBypassedEndpoints.includes(endpoint)) {
        return `${generatedEndpoint}?captcha_token=${captchaBypassToken}`;
    }

    return generatedEndpoint;
};

const EndpointService = {
    generateEndpoint,
    getCaptchaBypassedEndpoint,
};

export default EndpointService;
