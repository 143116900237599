/* eslint-disable max-len */
import {Utils} from '@busuu/legacy-core';

/**
 * Sentry errors ban list, regexp.
 * @type {Array}
 * Source: https://docs.sentry.io/platforms/javascript/#inboundfilters
 */
const IGNORE_ERRORS = [
    /**
     * Possible Google recaptcha error
     * Source: https://github.com/google/recaptcha/issues/269
     * and/or
     * Unhandled promise mostly due to browsers extensions, almost impossible to track or to fix.
     * Source: https://github.com/getsentry/sentry-javascript/issues/2210
     */
    /^Non-Error promise rejection captured with/,

    /**
     * Kaspersky Internet Security
     * Source: https://stackoverflow.com/questions/59411562/kaspersky-internet-security-blocks-ajax-requests
     */
    /GetCommandSrc/,

    /**
     * Might be related to some third party, not sure why this is happening
     * since this issues occurs on the HP where there's no video content.
     * Might also be because of some Youtube iframes.
     * Source: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
     */
    /The play\(\) request was interrupted by a call to pause\(\)/,

    /**
     * Evernote Web Clipper extension issue
     * https://stackoverflow.com/questions/50226631/error-there-is-no-clipping-info-for-given-tab
     */
    /^There is no clipping info for given tab$/,

    /**
     * Third party might access web storage
     */
    /^Internal error while estimating storage usage$/,

    /**
     * We assume there's some third party scripts injecting iframes
     * within the page and might try accessing them which triggers some security issues.
     * Source: https://stackoverflow.com/questions/25098021/securityerror-blocked-a-frame-with-origin-from-accessing-a-cross-origin-frame
     */
    /Blocked a frame with origin "https:\/\/www\.(busuu\.com)"/,

    /**
     * Stripe is loaded via script tag and can be occasionally not
     * present on the window element. Ignore logging this to Sentry.
     */
    /^createStripeInstance\(\) - Stripe instance missing on the window element\./,

    /**
     * Seems like this error is ocuring a lot and we're not using the IDBDatabase API.
     * Some possibly occuring from third party scripts that use Firebase
     */
    /^NotFoundError: IDBDatabase\.transaction: 'as' is not a known object store name/,
    /^SecurityError: IDBFactory\.open\(\) called in an invalid security context/,
    /^NotFoundError: The operation failed because the requested database object could not be found\. For example, an object store did not exist but was being opened\./,
    /Failed to execute 'transaction' on 'IDBDatabase'/,

    /**
     * RTC errors (we don't use RTC currently)
     * Possibly fired from Adjust
     */
    /^InvalidStateError: RTCPeerConnection is gone \(did you enter Offline mode\?\)/,

    /^Origin https:\/\/www\.busuu\.com is not allowed by Access-Control-Allow-Origin/,
];

export default {
    getIgnoreErrorsList: () => {
        return Utils.deepClone(IGNORE_ERRORS);
    },
};
