import FlagsCarousel from 'components/flags-carousel/flags-carousel-class.js';
import RtlFlagsCarousel from 'components/flags-carousel/rtl-flags-carousel-class.js';

const FlagsCarouselFactory = (wrapper, options = {}) => {
    let carousel;
    const rtl = window.BUSUU_GLOBALS.LANG_INTERFACE === 'ar';

    if (rtl) {
        carousel = new RtlFlagsCarousel(wrapper, options);
    } else {
        carousel = new FlagsCarousel(wrapper, options);
    }

    return carousel;
};

export default FlagsCarouselFactory;
