export const ERROR_TECHNICAL_ISSUE = {
    applicationCode: 'TECHNICAL_ISSUE',
    message: 'Technical issue',
};

export const ERROR_BAD_RESPONSE_FORMAT = {
    applicationCode: 'BAD_RESPONSE_FORMAT',
    message: 'Bad response format',
};

export const ERROR_UNKNOWN = {
    applicationCode: 'UNKNOWN_CODE',
    message: '',
};

export const ERROR_PARAMS_MISSING = {
    applicationCode: 'TECHNICAL_ISSUE',
    message: 'Params are missing',
};

export const ERROR_COUPON_UNAVAILABLE = {
    applicationCode: 'COUPON_UNAVAILABLE_OR_NOT_FOUND',
    message: '',
};
