export const KEY_NOT_FOUND = 'NOT FOUND';
/**
 * Replace `%%` with `%` as `%%` is used as an escape in some translations
 */
const replaceDoublePercentage = (translation) => {
    return translation.replace('%%', '%');
};
/**
 * Returns a translation from a given Crowdin key.
 */
const getTranslation = (key) => {
    return window.BUSUU_TRANSLATIONS[key] || KEY_NOT_FOUND;
};
/**
 * Replaces a single occurrence of %s in a given translation
 */
const replaceTranslation = (translation, arg) => {
    if (!translation || !arg) {
        return translation;
    }
    const translationWithArgs = translation.replace('%s', arg);
    return replaceDoublePercentage(translationWithArgs);
};
/**
 * Replaces PHP %n$s from arguments where n is index + 1
 * in a given translation
 */
const replaceTranslationMulti = (translation, args = []) => {
    if (!translation || !args.length) {
        return translation;
    }
    const translationWithArgs = args.reduce((trs, arg, index) => {
        return trs.replace(`%${index + 1}$s`, arg);
    }, translation);
    return replaceDoublePercentage(translationWithArgs);
};
/**
 * Returns a translation from a Crowdin key and calls replaceTranslation
 */
const getAndReplaceTranslation = (key, arg) => {
    return replaceTranslation(getTranslation(key), arg);
};
/**
 * Returns a translation from a Crowdin key and calls replaceTranslationMulti
 */
const getAndReplaceTranslationMulti = (key, args) => {
    return replaceTranslationMulti(getTranslation(key), args);
};
const TranslationsService = {
    getTranslation,
    getAndReplaceTranslation,
    getAndReplaceTranslationMulti,
    replaceTranslation,
    replaceTranslationMulti,
};
export default TranslationsService;
