import {Utils, ExceptionService} from '@busuu/legacy-core';
import debounce from 'lodash/debounce';
import PubSub from 'pubsub-js/src/pubsub.js';

/**
 * Private vars
 */
const filename = 'breakpoint-detection.js';
// Get breakpoint element
const breakpointIndicator = Utils.getById('breakpoint-indicator');
let currentState = null;

/**
 * Return current breakpoint state
 * @return {Integer}
 * states are:
 *     small:  1
 *     medium: 2
 *     large:  3
 *     above:  4
 * @public
 */
const getState = () => {
    return currentState;
};

/**
 * Return breakpoint state
 * @return {Integer}
 * @private
 */
const getCurrentState = () => {
    if (!breakpointIndicator) {
        ExceptionService.handle('warning', {
            filename,
            message: 'DOM Breakpoint Indicator is not present on current page.',
        });
        return false;
    }

    const index = parseInt(window.getComputedStyle(breakpointIndicator).getPropertyValue('z-index'), 10);
    return index || null;
};

/**
 * Attach a resize event on the window to store the
 * current breakpoint state and fire an event if the state has changed
 * @private
 */
const onResize = () => {
    const newState = getCurrentState();
    if (currentState !== newState) {
        currentState = newState;
        PubSub.publish('BREAKPOINT_DETECTION__CHANGED', currentState);
    }
};

/**
 * Initialization
 * @private
 */
const init = () => {
    /**
     * On load, store the current state
     */
    currentState = getCurrentState();

    /**
     * Listen for window resize events
     */
    window.addEventListener('resize', debounce(onResize, 300));
};

init(); // start!

export default {
    getState,
};
