import { Utils } from '@busuu/legacy-core';
export const V3_PAYWALL_FT_INFO_PAGE = 'free-trial-info';
export const V3_PAYWALL_PLANS_PAGE = 'plans';
/**
 *
 * Retrieves current language
 */
const getCurrentLanguage = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE || 'en';
};
/**
 * Returns a base URL for Web-payments project related pages, such as:
 * - Paywalls
 * - Checkout
 */
export const getWebPaymentsBaseUrl = () => {
    const localUrl = 'https://local.internal.busuu.com:3000/products';
    let baseUrl = `${window.location.origin}/products`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (Utils.isLocalEnvironment()) {
        baseUrl = localUrl;
    }
    return baseUrl;
};
/**
 * Retrieves url for the 3.0 paywall app (web-payments)
 */
export const getV3PaywallUrl = (page) => {
    const currentLang = getCurrentLanguage();
    const baseUrl = getWebPaymentsBaseUrl();
    switch (page) {
        case V3_PAYWALL_FT_INFO_PAGE:
            return `${baseUrl}/${currentLang}/${V3_PAYWALL_FT_INFO_PAGE}`;
        case V3_PAYWALL_PLANS_PAGE:
            return `${baseUrl}/${currentLang}/${V3_PAYWALL_PLANS_PAGE}`;
        default:
            return `${baseUrl}/${currentLang}`;
    }
};
/**
 * Returns Busuu 3.0 checkout url
 * @param {string} params query params that include price_id and cohort
 * @returns {string} checkout url
 */
export const getCheckoutUrl = (params) => {
    const baseUrl = getWebPaymentsBaseUrl();
    const currentLang = getCurrentLanguage();
    return `${baseUrl}/${currentLang}/checkout?${params}`;
};
