import APIRequestsService from 'endpoint/api-requests-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import {ExceptionService} from '@busuu/legacy-core';
import SharedDataService from 'data/shared-data-service.js';
import QueryParameters from 'helpers/query-parameters.js';
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import {ERROR_BAD_RESPONSE_FORMAT, ERROR_PARAMS_MISSING} from 'endpoint/api-requests-service.constants.js';

// Private vars
const filename = 'referral-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', {filename, message, data});
};

/**
 * Obtains the tokens from the query parameters related to referral users & exercises
 */
const referralParams = QueryParameters.getReferralParameters();

/**
 * Calls an API to retrieve public information about a user, given a code.
 * @param {string} unique user code
 */
const getReferralData = (referralCode) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`getReferralData() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    if (!referralCode) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('referralData').replace('{referral_code}', referralCode);

    return APIRequestsService.get(url).then(onSuccess).catch(onError);
};

/**
 * Fetches the information related to a conersation exercise that a user has completed
 * @param {String} unique exercise code
 */
const getReferralExerciseData = (exerciseCode) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`getReferralExerciseData() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    if (!exerciseCode) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('referralExerciseData').replace('{exercise_code}', exerciseCode);

    return APIRequestsService.get(url).then(onSuccess).catch(onError);
};

/**
 * Stores the referral user code parameter from the URL
 */
const getReferralUserCode = () => {
    return SharedDataService.getReferralUserCode();
};

/**
 * Returns the referral user code parameter if it exists in the URL.
 */
const storeReferralUserCode = () => {
    if (!referralParams || !referralParams.referral_code) {
        return null;
    }
    SharedDataService.storeReferralUserCode(referralParams.referral_code);
};

/**
 * Store the perk token parameter to storage if it exists in the URL.
 */
const storePerkToken = () => {
    if (!referralParams || !referralParams.perk_token) {
        return null;
    }
    SharedDataService.storePerkToken(referralParams.perk_token);
};

/**
 * Returns the referral code exercise parameter if it exists in the URL.
 */
const getReferralExerciseCode = () => {
    return referralParams.exercise_code || null;
};

/**
 * Returns the perk token from storage
 */
const getPerkToken = () => {
    return SharedDataService.getPerkToken();
};

const ReferralService = {
    getReferralData,
    getReferralExerciseData,
    storeReferralUserCode,
    getReferralUserCode,
    getReferralExerciseCode,
    storePerkToken,
    getPerkToken,
};

export default ReferralService;
