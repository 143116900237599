import {
    DE_CODE,
    EN_CODE,
    EN_US_CODE,
    ES_CODE,
    FR_CODE,
    IT_CODE,
    PL_CODE,
    PT_CODE,
    TR_CODE,
    JA_CODE,
    AR_CODE,
    KO_CODE,
    RU_CODE,
    ZH_CODE,
    NL_CODE,
} from '@busuu/languages';

/**
 * Marketing reorder list of flags based on course popularity
 * Used on the flag modules within the main landing pages
 * N.B: hardcoded since backend returns a single order of courses
 * N.B 2: NL exists only on certain interfaces (for now)
 */
export const COURSES_LANGS_BY_INTERFACE_LANGS = {
    en: [
        EN_CODE,
        ES_CODE,
        JA_CODE,
        FR_CODE,
        DE_CODE,
        KO_CODE,
        IT_CODE,
        AR_CODE,
        RU_CODE,
        ZH_CODE,
        TR_CODE,
        PT_CODE,
        NL_CODE,
        PL_CODE,
    ],
    es: [
        EN_CODE,
        FR_CODE,
        JA_CODE,
        IT_CODE,
        KO_CODE,
        PT_CODE,
        DE_CODE,
        ES_CODE,
        NL_CODE,
        ZH_CODE,
        RU_CODE,
        AR_CODE,
        NL_CODE,
        TR_CODE,
        PL_CODE,
    ],
    fr: [
        EN_CODE,
        FR_CODE,
        ES_CODE,
        JA_CODE,
        AR_CODE,
        DE_CODE,
        IT_CODE,
        KO_CODE,
        RU_CODE,
        PT_CODE,
        TR_CODE,
        NL_CODE,
        ZH_CODE,
        PL_CODE,
    ],
    de: [
        EN_CODE,
        ES_CODE,
        DE_CODE,
        JA_CODE,
        FR_CODE,
        IT_CODE,
        RU_CODE,
        TR_CODE,
        PL_CODE,
        AR_CODE,
        KO_CODE,
        PT_CODE,
        NL_CODE,
        ZH_CODE,
    ],
    [EN_US_CODE]: [
        ES_CODE,
        JA_CODE,
        EN_CODE,
        FR_CODE,
        DE_CODE,
        IT_CODE,
        KO_CODE,
        NL_CODE,
        RU_CODE,
        ZH_CODE,
        PT_CODE,
        AR_CODE,
        TR_CODE,
        PL_CODE,
    ],
    pt: [
        EN_CODE,
        ES_CODE,
        FR_CODE,
        JA_CODE,
        IT_CODE,
        KO_CODE,
        DE_CODE,
        PT_CODE,
        NL_CODE,
        RU_CODE,
        ZH_CODE,
        AR_CODE,
        NL_CODE,
        TR_CODE,
        PL_CODE,
    ],
    zh: [
        EN_CODE,
        JA_CODE,
        FR_CODE,
        ES_CODE,
        DE_CODE,
        RU_CODE,
        IT_CODE,
        KO_CODE,
        PT_CODE,
        ZH_CODE,
        NL_CODE,
        AR_CODE,
        PL_CODE,
        TR_CODE,
    ],
};

/**
 * List of countries where we can show the US flag
 * instead of the British flag and reorder the flags
 */
export const COUNTRY_TO_SHOW_US_FLAG = 'US';
