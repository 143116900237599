import {Utils} from '@busuu/legacy-core';

/**
 * This Class is managing the UI for the
 * Voucher and Discount Forms.
 * - Disable/Enable form submit
 * - Loading classes on submit
 * - Manage errors and translations
 */

class DiscountVoucherUI {
    constructor(submit, errorElement) {
        this.submit = submit;
        this.errorElement = errorElement;

        if (!this.submit || !this.errorElement) {
            throw new Error('DiscountVoucherUI() - 1 or more argument is missing.');
        }
    }

    getTranslation(key) {
        return window.BUSUU_TRANSLATIONS[key] || 'TRS NOT FOUND';
    }

    hideError() {
        Utils.addClass(this.errorElement, 'hide');
    }

    displayError(trs) {
        const text = trs ? this.getTranslation(trs) : this.getTranslation('ERROR');
        Utils.setText(this.errorElement, text);
        Utils.removeClass(this.errorElement, 'hide');
    }

    disableForm() {
        this.submit.setAttribute('disabled', 'disabled');
        Utils.addClass(this.submit, 'main-spinner-a');
    }

    enableForm() {
        this.submit.removeAttribute('disabled');
        Utils.removeClass(this.submit, 'main-spinner-a');
    }

    hideErrorsAndDisableForm() {
        this.hideError();
        this.disableForm();
    }

    showErrorAndEnableForm(trs) {
        this.displayError(trs);
        this.enableForm();
    }
}

export default DiscountVoucherUI;
