import InView from 'in-view';
import {Utils} from '@busuu/legacy-core';
import BreakPointDetection from 'helpers/breakpoint-detection.js';

// Private vars
const animationClass = 'animate-fade-in';
const animationClassVisible = 'animate-fade-in--visible';
const scrollOffset = 60; // Height of the navigation bar

/**
 * Handle the animation classes on each animation section
 * @param {DOM el}
 */
const fadeInSection = (el) => {
    Utils.addClass(el, animationClassVisible);
};

/**
 * Config for InView (0.X) is 0.X*100 (percent, e.g.: 0.2 = 20%)
 * Each time an InView `enter` event is fired, we call:
 * - fadeInSection - handles the animations on each section
 */
const initInView = () => {
    InView.threshold(0);
    InView.offset(scrollOffset);
    InView(`.${animationClass}`).on('enter', fadeInSection);
};

/**
 * Get device (based on breakpoints)
 */
const getDevice = () => {
    return BreakPointDetection.getState() >= 3 ? 'web' : 'mobile';
};

/**
 * For mobile/tablet view we don't want fadein animation
 * so we this will show all sections
 */
const showAllSections = () => {
    const hiddenSections = Utils.getElementsByClass(animationClass);
    hiddenSections.forEach((el) => {
        Utils.addClass(el, animationClassVisible);
    });
};

/**
 * Check if the page should be a module fadein page
 * @return {Boolean} true if the page contain at least on fadein module
 */
const isFadeinPage = () => {
    const animationClasses = Utils.getElementsByClass(animationClass);
    return Boolean(animationClasses.length);
};

const init = () => {
    if (!isFadeinPage()) {
        return false;
    }

    /**
     * Get device (based on breakpoints)
     * Desktop: init as normal with fadein animation
     * Mobile/Tablet: Don't init anything as it's a strange behavior for mobile
     * just add animationClassVisible to each section
     * to ensure that they get displayed on the page on load
     */
    if (getDevice() === 'mobile') {
        return showAllSections();
    }

    initInView();
};

init();
