import axios from 'axios';

axios.defaults.withCredentials = true;

const onSuccess = (response) => {
    return response.data;
};

export const getRequest = (url, config) => {
    return axios.get(url, config).then(onSuccess);
};

export const postRequest = (url, data, config) => {
    return axios.post(url, data, config).then(onSuccess);
};

export const putRequest = (url, data, config) => {
    return axios.put(url, data, config).then(onSuccess);
};

export const deleteRequest = (url, config) => {
    return axios.delete(url, config).then(onSuccess);
};

const APIRequestsService = {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    delete: deleteRequest,
};

export default APIRequestsService;
