import {Utils, ScriptLoader, ThirdPartyService} from '@busuu/legacy-core';
import PubSub from 'pubsub-js/src/pubsub.js';

/**
 * Trustpilot
 */
(function(root, doc, undefined) {

    const isVendorDisabled = function() {
        return ThirdPartyService.isVendorDisabled();
    };

    const onTrustpilotLoaded = function() {
        PubSub.publish('TRUSTPILOT_WIDGET_LOADED');
    };

    const init = () => {

        if (isVendorDisabled()) {
            return false;
        }

        const trustpilot = Utils.getById('trustpilot');
        if (!trustpilot) {
            return false;
        }

        const trustpilotTemplate = trustpilot.getAttribute('data-template-id');

        // Inject it! (delayed after load event)
        ScriptLoader.add(trustpilotTemplate, 'trustpilot', onTrustpilotLoaded);

    };

    init();

})(window, document);
