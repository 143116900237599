import Tracking from 'tracking/tracking-controller.ts';

let trackingData = {};

const getPageIdentifier = () => {
    return document.body.getAttribute('data-page-identifier') || null;
};

const getInterfaceLang = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE || null;
};

const getTrackingKey = () => {
    const pageId = getPageIdentifier();

    switch (pageId) {
        case 'home':
            trackingData = {
                page: pageId,
                interface_language: getInterfaceLang(),
            };
            return 'PAGE_VIEW_HOME';
        default:
            return null;
    }
};

const sendPageTracking = () => {
    const key = getTrackingKey();
    if (key) {
        Tracking.send(key, trackingData);
    }
};

const init = () => {
    sendPageTracking();
    Tracking.initCookieTracking();
};

init();
