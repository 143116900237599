const countriesData = {
    US: {images: ['us/us-community.jpg', 'us/us-10mins.jpg']},
    DE: {images: ['dach/dach-community.jpg', 'dach/dach-10mins.jpg']},
    GB: {images: ['us/us-community.jpg', 'us/us-10mins.jpg']},
    FR: {images: ['fr/fr-community.jpg', 'fr/fr-10mins.jpg']},
    BR: {images: ['br/brazil-community.jpg', 'br/brazil-10mins.jpg']},
    CH: {images: ['dach/DACH-community.jpg', 'dach/DACH-10mins.jpg']},
    AT: {images: ['dach/dach-community.jpg', 'dach/DACH-10mins.jpg']},
    CN: {images: ['cn/china-community.jpg', 'cn/china-10mins.jpg']},
};

const countriesDefault = {
    images: ['default/bg-header-2.jpg', 'default/bg-header-3.jpg'],
};
const countriesWherePasswordByPhoneDisabled = ['RU', 'PH', 'VE', 'SG'];

/**
 * List of countries where we want to disable resetting account by phone number
 */
const isResetPasswordByPhoneDisabled = (countryCode = '') => {
    return countriesWherePasswordByPhoneDisabled.includes(String(countryCode).toUpperCase()) || false;
};

export {countriesData, countriesDefault, isResetPasswordByPhoneDisabled};
