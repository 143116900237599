var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ExceptionService } from '@busuu/legacy-core';
import ConfigService from 'config/config-service.js';
import CurrentUser from 'user/current-user-service.js';
import SharedDataService from 'data/shared-data-service.js';
import { getPricing as getBusuuPricing, PRICING_DEFAULT_COUNTRY } from '@busuu/pricing';
import memoize from 'lodash/memoize';
// Vars
const FILENAME = 'pricing-service.ts';
let products = null;
// Pricing filters
const HIDDEN_INTERVAL_LENGTHS = [3, 24];
/**
 * Logs an error and optionally to Sentry with type: 'error'
 * @param {string} message
 * @param {object} error
 * @param {string} type
 */
const logError = (message, error, type = 'warning') => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ExceptionService.handle(type, {
        filename: FILENAME,
        message,
        data: error,
        sendToSentry: !!(type === 'error'),
    });
};
/**
 * Gets user config country code
 * Returns default if it fails
 * @param {object} user | user
 * @returns {Promise<string>}
 */
export const getCountryCode = (user) => __awaiter(void 0, void 0, void 0, function* () {
    // Get country code from the user or the config
    let countryCode = null;
    try {
        countryCode = (user === null || user === void 0 ? void 0 : user.country_code) || (yield ConfigService.getCountryCode());
    }
    catch (e) {
        // Fail silently
    }
    // Always check if the country code is supported
    return countryCode || PRICING_DEFAULT_COUNTRY;
});
/**
 * Removes products based on those we wish to hide
 * @param {Array} productsData
 * @return {Array}
 */
export const filterProducts = (productsData) => {
    return productsData.map((product) => (Object.assign(Object.assign({}, product), { prices: product.prices.filter((price) => !HIDDEN_INTERVAL_LENGTHS.includes(price.interval_count)) })));
};
/**
 * Gets pricing
 * Memoizing prevents fetching the call over and over
 * @param {string} pricingCohort
 * @param {string} type
 * @param {boolean} filterUnusedPrices | can be set to `false` to return all prices from backend
 * @returns {Promise}
 */
export const getPricing = memoize((pricingCohort, filterUnusedPrices = true) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        let user = null;
        try {
            user = yield CurrentUser.get();
        }
        catch (e) {
            // Fail silently
        }
        // Get supported country code
        const countryCode = yield getCountryCode(user);
        const couponCode = SharedDataService.getDiscountCoupon();
        // API req params
        const params = {
            user,
            countryCode,
            baseUrl: process.env.baseAPI,
            couponCode,
            pricingCohort,
            // TODO: Not sure why this is happening as the pricing service clearly defines the type...
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            filterUnusedPrices,
            interfaceLang: (_a = user === null || user === void 0 ? void 0 : user.languages) === null || _a === void 0 ? void 0 : _a.interface,
        };
        // Get pricing
        products = yield getBusuuPricing(params);
        // If pricing call returns [], re-fetch with default country code
        if (Array.isArray(products) && products.length === 0) {
            products = yield getBusuuPricing(Object.assign(Object.assign({}, params), { countryCode: PRICING_DEFAULT_COUNTRY }));
        }
        return products;
    }
    catch (e) {
        logError('getPricing() - Something went wrong', e);
        throw new Error(e);
    }
}));
