import {Utils} from '@busuu/legacy-core';
import TranslationsService from 'common/translations.service.ts';
import {storeEcommerceOriginValue} from '@busuu/marketing';
import CurrentUser from 'user/current-user-service.js';
import ToasterService from 'common/toaster.service.js';
import WindowLocationService from 'common/window-location.service.js';
import {getPricing} from 'pricing/pricing-service';
import {findFreeTrialPlan, buildCheckoutRequiredParams, PARTNER_GROUPS} from '@busuu/pricing';
import {getFTBasicPricingCohort} from '@busuu/experiments';
import {getFreeTrialEligibility} from 'free-trial/free-trial.service';
import {getCheckoutUrl} from 'redirects/redirects-service';

// Vars
const FILENAME = 'free-trial-benefits.js';
const UI_LOADING_CLASSNAME = 'ft--loading';
const claimBtn = Utils.getElementByClass('js-ft-button');
const ftWrapper = Utils.getById('js-ft-landing');
let plansData = null;
let selectedPlan = null;
let pricingCohort = null;
let currentUser = null;

const updateTitleText = (freeTrialDays) => {
    const elem = Utils.getElementByClass('js-ft-title');
    Utils.setText(
        elem,
        TranslationsService.getAndReplaceTranslationMulti(
            freeTrialDays === 30 ? 'YOUR_FIRST_MONTH_IS_ON_US' : 'YOUR_FIRST_WEEK_IS_ON_US'
        )
    );
};

const updateSubtitleText = (freeTrialDays) => {
    const elem = Utils.getElementByClass('js-ft-subtitle');
    const text = TranslationsService.getAndReplaceTranslationMulti('TRY_ALL_OF_BUSUU_UNLOCKED_FOR_X_DAYS', [
        freeTrialDays,
    ]);
    elem.innerHTML = text;
};

const updateButtonText = (freeTrialDays) => {
    const text = TranslationsService.getAndReplaceTranslationMulti('START_YOUR_X_DAY_FREE_TRIAL', [freeTrialDays]);
    Utils.setText(claimBtn, text);
};

/**
 * In case of an error, we redirect the user to login page
 * or dashboard if they are already logged in
 */
const errorRedirect = () => {
    const URL = ftWrapper.getAttribute('data-error-redirect');
    WindowLocationService.redirect(URL);
};

const onComplete = () => {
    Utils.removeClass(ftWrapper, UI_LOADING_CLASSNAME);
};

// If logged in, update the button HREF to the checkout
const updateLoggedInButtonBehaviour = () => {
    if (currentUser) {
        const params = buildCheckoutRequiredParams(selectedPlan.id, pricingCohort);
        const partner = Utils.getParameter('partner');
        const href = getCheckoutUrl(params);
        claimBtn.href = `${href}${partner ? `&partner=${partner}` : ''}`;
    }
};

/**
 * When selecting a trial, do a few things
 *
 * 1. If the Free trial plan doesn't have a duration, throw error
 * 2. Store eCommerce origin
 * 3. Send to checkout
 *
 * @param {Event} e
 */
const onTrialSelection = function (e) {
    if (e) e.preventDefault();

    if (!selectedPlan.free_trial_duration_days) {
        return ToasterService.error(TranslationsService.getTranslation('ERROR'));
    }

    storeEcommerceOriginValue('FREE_TRIAL_ONBOARDING');
    WindowLocationService.redirect(this.href);
};

/**
 * We throw an error on load if the user exists and
 * they are not eligible for trial
 */
const checkIfUserIsEligible = async (user) => {
    const isEligibleForFreeTrial = await getFreeTrialEligibility();
    if (user && !isEligibleForFreeTrial) {
        throw new Error(`${FILENAME} checkIfUserIsEligible() - User is not eligible for free trial.`);
    }
};

/**
 * Returns the user group, specifically to determine
 * if we need to get different pricing for partners
 *
 * NB: We do NOT support `FREE_TRIAL_REFERRAL` for this module
 * as there's no use-case for it. We have another page `/refer` for receiving a FT.
 *
 * @returns {String} userGroup
 */
const getPricingCohort = () => {
    const partner = Utils.getParameter('partner');
    if (partner && PARTNER_GROUPS[partner]) {
        return PARTNER_GROUPS[partner];
    }
    const cohortBasic = getFTBasicPricingCohort();
    return cohortBasic;
};

// Inits the UI
const initUI = (freeTrialDays) => {
    updateTitleText(freeTrialDays);
    updateSubtitleText(freeTrialDays);
    updateButtonText(freeTrialDays);
    claimBtn.addEventListener('click', onTrialSelection);
};

/**
 * Initialises the FT page `/claim-trial`
 */
const init = async () => {
    if (!ftWrapper) return false;

    /**
     * Check if user is present
     * If logged-out, silently fail and the user will be redirected to login on click
     */
    try {
        currentUser = await CurrentUser.get();
    } catch (e) {
        // Silently fail
    }

    // Next, get pricing and FT details
    try {
        if (currentUser) {
            await checkIfUserIsEligible(currentUser);
        }
        pricingCohort = getPricingCohort();
        plansData = await getPricing(pricingCohort);
        selectedPlan = findFreeTrialPlan(plansData);
        initUI(selectedPlan.free_trial_duration_days || 0);
        updateLoggedInButtonBehaviour();
        onComplete();
    } catch (e) {
        errorRedirect(e);
    }
};

init();
