import {Utils, ThirdPartyService} from '@busuu/legacy-core';

/**
 * Zendesk popup
 */
(function(root, doc, undefined) {

    'use strict';

    /**
     * Reaaalllly slow script, messes with animations and the page
     * can be a bit unusable while this script is loading.
     * Deferred the load by 10s to try to avoid everything loading at once.
     * The user won't need the help functionality within their first 10s of visiting anyway.
     */
    const loadZendesk = () => {
        setTimeout(() => {
            window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost="busuu.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();
        }, 10000);
    };

    const isVendorDisabled = () => {
        return ThirdPartyService.isVendorDisabled();
    };

    const init = () => {
        if (isVendorDisabled()) {
            return false;
        }

        const zendesk = Utils.getById('zendeskId');
        if (!zendesk) {
            return false;
        }

        root.addEventListener('load', loadZendesk);
    };

    init();

})(window, document);
