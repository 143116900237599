/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Utils } from '@busuu/legacy-core';
import Tracking from 'tracking/tracking-controller';
import WindowLocationService from 'common/window-location.service.js';
const redirect = (href) => {
    WindowLocationService.redirect(href);
};
const onLanguageChangeHandler = function (e) {
    e.preventDefault();
    const href = this.getAttribute('href');
    if (href) {
        const languageSelected = this.getAttribute('data-lang');
        Tracking.send('INTERFACE_LANGUAGE_SELECTED', {
            language_selected: languageSelected,
        });
        // Add delay to ensure tracking event is sent before redirecting
        setTimeout(redirect.bind(null, href), 500);
    }
};
const initLanguageSwitcher = (switcher) => {
    Utils.delegate(switcher, 'js-switcher', 'click', onLanguageChangeHandler);
};
const init = () => {
    const switcher = Utils.getById('language-switcher-dropdown');
    if (switcher) {
        initLanguageSwitcher(switcher);
    }
};
init();
