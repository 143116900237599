import {getEndpoint, ENDPOINTS} from '@busuu/endpoints';
import {Utils, ExceptionService} from '@busuu/legacy-core';
import APIRequestsService from 'endpoint/api-requests-service.js';
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import {
    ERROR_BAD_RESPONSE_FORMAT,
    ERROR_PARAMS_MISSING,
    ERROR_TECHNICAL_ISSUE,
    ERROR_COUPON_UNAVAILABLE,
} from 'endpoint/api-requests-service.constants.js';

// Private vars
const FILENAME = 'discount-service.js';

const logError = (message, error, type = 'warning') => {
    ExceptionService.handle(type, {
        filename: FILENAME,
        message,
        data: error,
    });
};

/**
 * Returns a discount for a given coupon and its validity
 * @param  {String} inputValue - value from the text input
 * @return {Promise}
 * @public
 */
const getCouponValidity = (inputValue) => {
    const coupon = inputValue ? Utils.clean(inputValue) : null;

    const onError = (error = {}) => {
        const {applicationCode, message} = decomposeAxiosError(error, ERROR_TECHNICAL_ISSUE);

        logError(`getCouponValidity() error: ${applicationCode} - ${message}`, error);

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        if (!response.data.can_i_use || !response.data.saving) {
            return onError(composeAxiosError(ERROR_COUPON_UNAVAILABLE));
        }

        return {
            ...response.data,
            coupon,
        };
    };

    if (!coupon) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = getEndpoint({
        baseUrl: process.env.baseAPI,
        endpoint: ENDPOINTS.PRICING_DISCOUNT,
        replacements: {'{coupon}': coupon},
    });

    return APIRequestsService.get(url).then(onSuccess).catch(onError);
};

const DiscountService = {
    getCouponValidity,
};

export default DiscountService;
