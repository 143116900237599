/* eslint-disable no-unused-expressions */
import {Utils} from '@busuu/legacy-core';
import ItemRotator from 'components/item-rotator-class.js';
import ItemChildHeightCalculator from 'components/item-child-height-calculator-class.js';
import FlagsCarouselFactory from 'components/flags-carousel/flags-carousel-factory';
import {initBGHeader} from 'components/main-header-wave-background.js';
import Tracking from 'tracking/tracking-controller.ts';
import {DYNAMIC_FLAGS_READY_EVENT} from 'components/flags-list-constants.js';
import PubSub from 'pubsub-js/src/pubsub.js';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import throttle from 'lodash/throttle';

const HEADER_ROTATION_INTERVAL = 3000;

const initNavLinksTracking = () => {
    const NAV_LINKS = {
        nav_link_howItWorks: [Utils.getById('nav-link-how-works'), Utils.getById('nav-link-how-works-dropdown')],
        nav_link_reviews: [Utils.getById('nav-link-reviews'), Utils.getById('nav-link-reviews-dropdown')],
        nav_link_liveLessons: [Utils.getById('nav-link-live-lessons'), Utils.getById('nav-link-live-lessons-dropdown')],
        nav_link_plans: [Utils.getById('nav-link-plans'), Utils.getById('nav-link-plans-dropdown')],
    };

    const DESTINATIONS = {
        nav_link_howItWorks: 'how-it-works',
        nav_link_reviews: 'app-trust',
    };

    const clickAction = (key) => {
        if (NAV_LINKS[key]) {
            const trackingData = {
                type: `${key}`,
                page: window.location.href,
            };

            const destination = DESTINATIONS[key];

            const trackEvent = (element) => {
                if (element) {
                    element.addEventListener('click', (e) => {
                        const scrollDestination = Utils.getById(destination);
                        if (scrollDestination) {
                            e.preventDefault();
                            scrollDestination.scrollIntoView({
                                behavior: 'smooth',
                            });
                        }
                        Tracking.sendAndWait('HOME_NAV_LINKS_CLICKED', trackingData);
                    });
                }
            };

            NAV_LINKS[key].forEach(trackEvent);
        }
    };

    Object.keys(NAV_LINKS).forEach(clickAction);
};

const initSignupsCTATracking = () => {
    const SIGNUP_CTAS = {
        nav_signup_cta: Utils.getById('nav-signup-cta'),
        header_signup_cta: Utils.getById('main-header-cta'),
        awards_signup_cta: Utils.getById('app-awards-cta'),
    };

    const clickAction = (key) => {
        if (SIGNUP_CTAS[key]) {
            const trackingData = {
                type: `${key}`,
                page: window.location.href,
            };
            SIGNUP_CTAS[key].addEventListener('click', () =>
                Tracking.sendAndWait('HOME_SIGNUPS_CTA_CLICKED', trackingData)
            );
        }
    };

    Object.keys(SIGNUP_CTAS).forEach(clickAction);
};

const initTitleTextRotator = (wrapperClass) => {
    const titlesWrapper = Utils.getElementByClass(wrapperClass);

    if (titlesWrapper) {
        new ItemChildHeightCalculator(titlesWrapper, {
            calculateClass: 'main-header-wave__title-rotator-item',
        });

        new ItemRotator(titlesWrapper, {
            interval: HEADER_ROTATION_INTERVAL,
            initializedClass: 'main-header-wave__title-rotator--initialized',
            itemPrevClass: 'main-header-wave__title-rotator-item--prev',
            itemActiveClass: 'main-header-wave__title-rotator-item--active',
            itemNextClass: 'main-header-wave__title-rotator-item--next',
        });
    }
};

const initFlagsCarousel = () => {
    const container = Utils.getElementByClass('choose-language-scrollable__flags-container');
    const content = Utils.getElementByClass('choose-language-scrollable__flags-container-content');

    const shouldScroll = () => content && container && content.scroll && content.scrollWidth > container.clientWidth;

    const initCarousel = () => {
        const carouselWrapper = Utils.getElementByClass('choose-language-scrollable__carousel');

        FlagsCarouselFactory(carouselWrapper, {
            container,
            content,
            leftArrow: Utils.getElementByClass('choose-language-scrollable__carousel-arrow--left'),
            rightArrow: Utils.getElementByClass('choose-language-scrollable__carousel-arrow--right'),
        });
    };

    // Init a static flag list carousel ?
    if (shouldScroll()) {
        initCarousel();
        return;
    }

    // Init a dynamic flag list carousel ?
    PubSub.subscribe(DYNAMIC_FLAGS_READY_EVENT, function () {
        if (shouldScroll()) {
            initCarousel();
        }
    });
};

const scrollCheck = () => {
    try {
        const normalNavbar = Utils.getElementByClass('nav-sticky__navigation-normal');
        const transparentNavbar = Utils.getElementByClass('nav-sticky__navigation-inverted');
        const navDotsIcon = Utils.getElementByClass('responsive-nav__icon');
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition > 0) {
            normalNavbar && normalNavbar.classList.remove('hidden');
            transparentNavbar && transparentNavbar.classList.add('hidden');
            navDotsIcon && navDotsIcon.className.remove('responsive-nav__icon-blue');
            navDotsIcon && navDotsIcon.className.add('responsive-nav__icon-white');
        } else {
            normalNavbar && normalNavbar.classList.add('hidden');
            transparentNavbar && transparentNavbar.classList.remove('hidden');
            navDotsIcon && navDotsIcon.className.remove('responsive-nav__icon-white');
            navDotsIcon && navDotsIcon.className.add('responsive-nav__icon-blue');
        }
    } catch (er) {
        // dies silently for now
    }
};

const initNavbar = () => {
    window.addEventListener('scroll', throttle(scrollCheck, 200));
};

const init = () => {
    const carouselWrapper = Utils.getById('js-language-scrollable');
    const onMobile = BreakPointDetection.getState() < 2;

    initSignupsCTATracking();
    initNavLinksTracking();
    initNavbar();
    if (carouselWrapper && !onMobile) {
        initFlagsCarousel();
    }
    initBGHeader()
        .catch(() => {})
        .then(initTitleTextRotator.bind(null, 'js-title-rotator'))
        .then(initTitleTextRotator.bind(null, 'js-subtitle-rotator'));
};

init();

export {init};
