/**
 * Loads Floodlight tag
 * https://support.google.com/campaignmanager/answer/7554821?sjid=1357535607244422284-EU
 */
export const loadFloodlight = (floodlightId) => {
    try {
        (function (w, d, s, l, i) {
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            j.async = true;
            j.type = 'text/javascript';
            j.src = `https://www.googletagmanager.com/gtag/js?id=${i}`;
            f.parentNode.insertBefore(j, f);
            
            w[l] = w[l] || [];
            function gtag(){
                w[l].push(arguments);
            }
            gtag('js', new Date());
            gtag('config', i)
        })(window, document, 'script', 'dataLayer', floodlightId);
    } catch (e) {
        console.error('Cannot load gtag', e);
    }
};

/**
 * Loads Floodlight image with parameters
 * https://support.google.com/campaignmanager/answer/7554821?sjid=1357535607244422284-EU
 */
export const loadFloodlightImage = (params) => {
    try {
        (function (d, s, n, i, p) {
            const f = d.getElementsByTagName(s)[0];
            const k = d.createElement(n)
            const j = d.createElement(i);
            j.src = `https://ad.doubleclick.net/ddm/activity/src=${p.src};type=${p.type};cat=${p.cat};u1=${p.role};u2=${p.countryCode};u3=${p.customerDevice};u4=${p.languageInterface};u5=${p.languageLearned};u7=${p.customerId};u11=${p.registrationDate};dc_lat=;dc_rdid=;gdpr=${p.gdpr};gdpr_consent=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?`;
            j.width = 1;
            j.height = 1;
            j.alt = '';
            k.appendChild(j);
            f.parentNode.insertBefore(k, f);
        })(document, 'script', 'noscript', 'img', params);
    } catch (e) {
        console.error('Cannot load gtag', e);
    }
}
