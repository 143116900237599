import FlagsCarousel from 'components/flags-carousel/flags-carousel-class.js';
import {LEFT, RIGHT} from 'components/flags-carousel/flags-carousel-constants.js';

class RtlFlagsCarousel extends FlagsCarousel {
    constructor(wrapperElement, options = {}) {
        super(wrapperElement, options);
    }

    checkButtonsState(value) {
        const {content, leftArrow, rightArrow} = this.options;
        const maxScrollLeft = content.scrollWidth - content.clientWidth;

        if (value <= 0) {
            this.disableButton(leftArrow);
        }

        if (value >= maxScrollLeft) {
            this.disableButton(rightArrow);
        }
    }

    clickButton(button) {
        const {content, leftArrow, rightArrow} = this.options;
        let newValue;

        switch (button) {
            case LEFT:
                this.enableButton(leftArrow);
                newValue = content.scrollLeft - this.getNewScrollValue();
                break;
            case RIGHT:
            default:
                this.enableButton(rightArrow);
                newValue = content.scrollLeft + this.getNewScrollValue();
                break;
        }

        this.scrollTo(newValue);
        this.checkButtonsState(newValue * -1);
    }

    initInteractionEvents() {
        const {leftArrow, rightArrow} = this.options;

        if (leftArrow) {
            leftArrow.addEventListener('click', this.clickButton.bind(this, RIGHT));
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', this.clickButton.bind(this, LEFT));
        }

        this.disableButton(leftArrow);
    }
}

export default RtlFlagsCarousel;
