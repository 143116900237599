import {storeEcommerceOriginValue} from '@busuu/marketing';
import {Utils} from '@busuu/legacy-core';

const NAV_CLASS = 'main-nav';
const STICKY_NAV_CLASS = 'nav-sticky';
const STICKY_BUTTON_CLASS = 'main-header-wave__cta-wrapper';
const NEW_STICKY_BANNER_MAIN = 'nav-sticky-simplified';
const NEW_STICKY_BANNER_NOT_INVERTED = 'nav-sticky__navigation-normal';
const BANNER_ID = 'sticky-promo-banner';
const HAS_PROMO_BANNER_ATTRIBUTE = 'data-has-promo-banner';
let hasPromoBanner = null;

/**
 * Dynamically set positioning of modular header based on height of promo banner
 * and set positioning of sticky CTA to stay below the promo banner if required
 */
export const initStickyPromoBanner = () => {
    const navElement =
        Utils.getElementByClass(NAV_CLASS) ||
        Utils.getElementByClass(STICKY_NAV_CLASS) ||
        Utils.getElementByClass(NEW_STICKY_BANNER_MAIN);
    const navElementSticky = Utils.getElementByClass(NEW_STICKY_BANNER_NOT_INVERTED);
    const stickyButtonElement = Utils.getElementByClass(STICKY_BUTTON_CLASS);
    const bannerElement = Utils.getById(BANNER_ID);

    if (navElement) {
        hasPromoBanner = navElement.getAttribute(HAS_PROMO_BANNER_ATTRIBUTE);
    }

    // If CTA is sticky then move it below the banner
    if (stickyButtonElement && bannerElement) {
        const styles = window.getComputedStyle(stickyButtonElement);
        if (styles.position === 'sticky') {
            stickyButtonElement.style.top = `${bannerElement.clientHeight + 16}px`;
        }
    }

    // Move fixed navbar so it does not block the promo banner
    if (navElement && bannerElement && hasPromoBanner) {
        const bannerHeight = bannerElement.clientHeight;
        navElement.style.top = `${bannerHeight}px`;
        if (navElementSticky) {
            navElementSticky.style.top = `${bannerHeight}px`;
        }
    }

    if (bannerElement) {
        bannerElement.addEventListener('click', () => {
            const eCommerceOrigin = bannerElement.getAttribute('data-ecommerce-origin') || '';
            const redirectURL = bannerElement.getAttribute('data-redirect') || '/';

            storeEcommerceOriginValue(eCommerceOrigin.toUpperCase());
            window.location.href = redirectURL;
        });
    }
};
