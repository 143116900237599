import {Utils} from '@busuu/legacy-core';
import ConfigService from 'config/config-service.js';

const COUNTRY_CODE_ALLOWED = ['US', 'GB'];

const init = async () => {
    const studentDiscountWrapper = Utils.getById('student-discount');

    if (studentDiscountWrapper) {
        const countryCode = await ConfigService.getCountryCode().catch(() => null);

        if (countryCode) {
            const visibleElem = COUNTRY_CODE_ALLOWED.includes(countryCode)
                ? `student-discount-${countryCode}`
                : 'student-discount-disclaimer';

            Utils.removeClass(Utils.getElementByClass(visibleElem), 'hidden');
        } else {
            // Show error message
            Utils.removeClass(Utils.getElementByClass('student-discount-error'), 'hidden');
        }
        // Hide loader
        Utils.addClass(Utils.getElementByClass('student-discount-loader'), 'hidden');
    }
};

init();
