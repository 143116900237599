import {Utils} from '@busuu/legacy-core';
import Tracking from 'tracking/tracking-controller.ts';

// Private vars
const faqWrapperId = 'js-faq-module';
const faqItemClass = 'js-faq-module-item-clickable';
const faqWrapperActiveClass = 'faq-module__item--active';
const faqQuestionNumberAttr = 'data-faq-num';
const faqTypeAttr = 'data-faq-type';
let faqWrapper = null;
let faqType = null;
let faqItems = null;

const handleFaqItemClick = function (e) {
    if (e) {
        e.preventDefault();
    }

    const faqQuestionNumber = this.getAttribute(faqQuestionNumberAttr) || null;
    Tracking.send('FAQ_SELECTED', {
        question: Number(faqQuestionNumber),
        type: faqType || 'unknown',
    });

    const hasActiveClass = Utils.hasClass(this, faqWrapperActiveClass);

    // Remove classes from all items
    faqItems.forEach((item) => {
        Utils.removeClass(item, faqWrapperActiveClass);
    });

    // If class wasn't present, add it on given item
    if (!hasActiveClass) {
        Utils.addClass(this, faqWrapperActiveClass);
    }
};

const init = () => {
    faqWrapper = Utils.getById(faqWrapperId);

    // Only proceed if parent is found
    if (!faqWrapper) {
        return false;
    }

    faqType = faqWrapper.getAttribute(faqTypeAttr) || null;
    faqItems = Utils.getElementsByClass(faqItemClass, faqWrapper);
    faqItems.forEach((item) => {
        item.addEventListener('click', handleFaqItemClick);
    });
};

init();

export {init};
