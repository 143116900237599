/* eslint-disable max-len */
import BreakPointDetection from 'helpers/breakpoint-detection';
import TrackingConstants from 'tracking/tracking-constants';
import {loadFloodlight, loadFloodlightImage} from 'third-party/script-floodlight';
import {OneTrustCookieConsentService} from '@busuu/cookie-consent';
import {isCountryGDPRProtected} from '@busuu/countries';
import ConfigService from 'config/config-service.js';

// The option responsible for showing ads is "Store and/or access information on a device" which is a consent group that we currently can't retrieve.
// Chegg team will be working on providing more granularity in terms of consents.
// We suppose that if a user opted in for any conventional cookies (here we check for 'trg' cookies), they chose "Allow all" option.
// In the opposite case we suppose they chose "Reject all" option. Very low percentage of users will toggle specific consent options.
// In case of a non-GDPR country we don't need a consent to show third party ads.
const getHasCookiesConsent = async () => {
    const activeCookiesGroups = OneTrustCookieConsentService.getActiveCookieCategories();
    try {
        const countryCode = await ConfigService.getCountryCode();
        const userIsInGDPRCountry = isCountryGDPRProtected(countryCode);
        return !userIsInGDPRCountry || activeCookiesGroups?.includes('trg');
    } catch (error) {
        return false;
    }
};

const FLOODLIGHT_ID = 'DC-9431406';

let commonData = {};

/**
 * Returns user device name
 */
const getDevice = () => {
    return BreakPointDetection.getState() >= 3 ? 'web' : 'mobile';
};

/**
 * Sends google events
 */
function gtag() {
    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
}

/**
 * Callback to process registration event
 */
const registrationEventCallback = () => {
    const type = 'brust0';
    const cat = 'busuu001';

    gtag('event', 'conversion', {
        allow_custom_scripts: true,
        u1: commonData.role,
        u2: commonData.countryCode,
        u3: commonData.customerDevice,
        u4: commonData.languageInterface,
        u5: commonData.languageLearned,
        u7: commonData.customerId,
        u11: commonData.registrationDate,
        send_to: `${FLOODLIGHT_ID}/${type}/${cat}+standard`,
        dc_custom_params: {
            match_id: [commonData.customerId],
        },
        user_data: {
            email: commonData.hashedUserEmail,
        },
    });

    loadFloodlightImage({...commonData, src: FLOODLIGHT_ID, type, cat});
};

/**
 * Maps event callbacks to event names
 */
const EVENT_CALLBACK_MAPPING = {
    [TrackingConstants.USER_REGISTERED_SUCCESS.real_event_name]: registrationEventCallback,
};

/**
 * Loads Floodlight script
 */
const load = () => {
    loadFloodlight(FLOODLIGHT_ID);
};

/**
 * Stores extra data for Floodlight events
 */
const identifyUser = async function (user) {
    commonData = {
        ...commonData,
        role: user.is_premium ? 'premium' : 'free',
        customerId: user.uid,
        countryCode: user.country_code,
        customerDevice: getDevice(),
        languageInterface: user.languages ? user.languages.interface : null,
        languageLearned: user.languages ? user.languages.learning_default : null,
        registrationDate: new Date(user.created * 1000).toString(),
        gdpr: (await getHasCookiesConsent(user.country_code)) ? 1 : 0,
        hashedUserEmail: user.hashed_email,
    };
};

/**
 * Handles events
 */
const sendEvent = function (eventTemplate = {}) {
    const eventFunction = EVENT_CALLBACK_MAPPING[eventTemplate.real_event_name];

    if (eventFunction) {
        eventFunction();
    }
};

const Floodlight = {
    sendEvent,
    identifyUser,
    load,
};

export default Floodlight;
