import Tracking from 'tracking/tracking-controller.ts';
import {retrieveEcommerceOriginValue} from '@busuu/marketing';

const getUrlParams = () => {
    const urlParams = {};
    const queryString = window.location.search.slice(1);
    const paramsArray = queryString.split('&');

    paramsArray.forEach((param) => {
        const [key, value] = param.split('=');
        if (key) {
            urlParams[key] = decodeURIComponent(value);
        }
    });

    return urlParams;
};

const sendTracking = (key, data) => {
    Tracking.send(key, data);
};

const sendEvent = () => {
    const data = {};
    const origin = retrieveEcommerceOriginValue();
    const paramsObject = getUrlParams();
    if (origin) {
        data.ecommerce_origin = origin;
    }
    data.atts = {
        ...paramsObject,
    };

    sendTracking('EDU_PAGE_VIEWED', data);
};

const isOnEduPage = () => {
    const eduURL = '/c/edu';
    const currentUrl = window.location.href;

    return currentUrl.includes(eduURL);
};

const init = () => {
    if (isOnEduPage()) sendEvent();
};

init();
